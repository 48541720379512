import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import BlogPosts from '../components/BlogPosts'
// import { GatsbySeo } from 'gatsby-plugin-next-seo';
import SEO from "../components/seo/seo-new"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

// Query for the Blog Home content in Prismic
export const query = graphql`
  query myBlogQuery ($blogUID: String, $language: String!) {
    allPrismicPost( sort: {fields: last_publication_date, order: DESC} filter: {tags: {eq: $blogUID}, lang: {eq: "en-us"}}){
      edges {
        node {
          url
          id
          lang
          uid
          last_publication_date
          type
          data {
            meta_description
            meta_title
            title {
              richText
            }
            image {
              url
            }
            date_updated
            date_posted
            picture {
              url
            }
            author_name {
              text
            }
            description {
              text
            }
            body {
              ... on PrismicPostDataBodyText {
                id
                slice_label
                slice_type
                primary {
                  text {
                    richText
                  }
                }
              }

            }
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`


export const Homepage = ({ data, pageContext }) => {
  const { t } = useTranslation();

  if (!data) return null
  // Define the Blog Home & Blog Post content returned from Prismic
  const posts = data.allPrismicPost.edges
  const lang = data.locales.edges[0].node.language

  const blogName = pageContext.blogName

  const titles = `${blogName} ` + t('Blog by') + 'taxomate'
  const descriptions = t('Best') + ` ${blogName} ` + t('resources, including tips, tools, and tricks for accounting and bookkeeping.')
  let url;
  lang == "en" ?
    url = `/${pageContext.blogUID}/`
    :
    url = `/${lang}/${pageContext.blogUID}/`


  return (
    <Layout>

      <SEO
        title={titles}
        description={descriptions}
        image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
        pathname={`/${pageContext.blogUID}/`}
        originalPathname={`/${pageContext.blogUID}/`}
        lang={lang}
        alternate
      />

{/*
      <GatsbySeo
        description={description}
        title={title}
        canonical={`https://taxomate.com/${pageContext.page}`}
        openGraph={{
          url: `https://taxomate.com/${pageContext.page}`,
          title: title,
          type: 'website',
          description: description,
          images: [
            {
              url: "https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg",
              width: 1200,
              height: 628,
              alt: title,
            },
          ],
        }}
        twitter={{
          handle: '@gettaxomate',
          site: '@taxomate',
          cardType: 'summary_large_image',
        }}
        languageAlternates={[
          {
            hrefLang: 'fr',
            href: `https://fr.taxomate.com/${pageContext.page}`,
          },
          {
            hrefLang: 'es',
            href: `https://es.taxomate.com/${pageContext.page}`,
          }
        ]}

      /> */}


      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="flex">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center py-10">
            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">{blogName} <Trans>Resources by</Trans> Taxomate</h1>
          </div>
          <BlogPosts posts={posts} />
        </div>
      </div>
    </Layout>
  )
}

export default Homepage
import React from 'react'
import { Link } from 'gatsby'
import { RichText, Date } from 'prismic-reactjs'
import Author from "../images/blog/me.jpg"



// A summary of the Blog Post
const PostSummary = ({ post, id }) => {
//   let postDate = Date(post.node.data.date_posted)
//   postDate = postDate
//     ? new Intl.DateTimeFormat('en-US', {
//       month: 'short',
//       day: '2-digit',
//       year: 'numeric',
//     }).format(postDate)
//     : ''

  const lastPub = post.node.last_publication_date.substring(0, 10)
  const lastPublished = new Date(lastPub);
  const lastDated = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }).format(lastPublished)

  // // Default title when post has no title set
  const defaultTitle = 'Untitled'
  return (
    <div className="flex flex-col rounded-lg shadow overflow-hidden" key={id}>
      <Link to={post.node.url}>

        <div class="flex-shrink-0 bg-gradient-to-r from-blue-600 to-blue-500 p-5 rounded-t-lg">

        <h2 className="text-2xl font-semibold text-white mt-3">
          {/* We render a link to a particular post
         * using the linkResolver for the url and its title */}
          {RichText.asText(post.node.data.title.richText).length !== 0
            ? RichText.asText(post.node.data.title.richText)
            : defaultTitle}
          </h2>
          </div>

        {/* Renders a small preview of the post's text */}
        <div className="mt-3 text-xs text-gray-500 p-3">
          {post.node.data.meta_description}
        </div>

        <div className="my-3 flex ml-5 align-middle">
          <div className="shrink-0">
            <img className="h-6 w-6 rounded-full" src=

              {
                post.node.data.picture.url !== null
                  ? post.node.data.picture.url
                  : Author
              }

              alt={`${post.node.data.title.richText}`} />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">
              {
                post.node.data.author_name.text.length !== 0
                  ? post.node.data.author_name.text
                  : "Michael Schwartz"
              }
            </p>
          </div>
          <div className="flex space-x-1 pl-6 text-sm text-gray-500">
            <time>{lastDated}</time>
          </div>
        </div>

      </Link>

    </div>
  )
}
export default ({ posts }) => {
  if (!posts) return null
  return (
    <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">

      {posts.map((post) => (
        <div className='pb-6'>
          <PostSummary post={post} key={post.node.id} className="flex flex-col rounded-lg shadow overflow-hidden" />
        </div>
      ))}
    </div>
  )
}
